export default function Support(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.2344C21.6094 12.5156 21.9844 13.125 21.9844 13.8281V16.125C21.9844 16.875 21.6094 17.4844 21 17.7656V18.9844C21 20.1094 20.1094 21 18.9844 21H12C11.4375 21 11.0156 20.5312 11.0156 20.0156C11.0156 19.4531 11.4375 18.9844 12 18.9844H18.9844V11.9062C18.9844 7.96875 15.75 4.78125 11.7656 4.92188C7.96875 5.01562 5.01562 8.34375 5.01562 12.1875V17.0156C5.01562 17.5312 4.54688 18 3.98438 18C2.90625 18 2.01562 17.1094 2.01562 15.9844V14.0156C2.01562 13.2656 2.39062 12.6094 3 12.2812C3 6.65625 7.3125 3 12 3C16.7344 3 21 6.75 21 12.2344ZM8.01562 12.9844C8.01562 13.5469 8.4375 14.0156 9 14.0156C9.5625 14.0156 9.98438 13.5469 9.98438 12.9844C9.98438 12.4688 9.5625 12 9 12C8.4375 12 8.01562 12.4688 8.01562 12.9844ZM14.0156 12.9844C14.0156 13.5469 14.4375 14.0156 15 14.0156C15.5625 14.0156 15.9844 13.5469 15.9844 12.9844C15.9844 12.4688 15.5625 12 15 12C14.4375 12 14.0156 12.4688 14.0156 12.9844ZM18 11.0156C14.8594 11.0156 12.1875 9.1875 10.875 6.5625C10.3594 9.23438 8.48438 11.4375 6 12.4688C5.76562 8.53125 9 6 12.0469 6C15.0469 6 17.5312 8.20312 18 11.0156Z"
        fill="#FFB800"
      />
    </svg>
  );
}
