export default function MenuMob1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.2 2a2 2 0 0 0-2 2v5.5a1 1 0 0 0 1 1h6.623a2.38 2.38 0 0 1 4.754 0H21.2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-16Zm6.1 10c.174.232.39.43.636.585v1.976a1.25 1.25 0 1 0 2.5 0v-1.959c.258-.157.483-.362.664-.602h7.1a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-7a1 1 0 0 1 1-1h7.1Z"
        clipRule="evenodd"
      />
    </svg>
  );
}