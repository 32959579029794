import './headerTop.scss';

import { TopDrop } from '../top-drop/topDrop';

import icon_online from '../../../scss/images/icon-online-people.svg';
import icon_top from '../../../scss/images/icon-top.svg';
import icon_top2 from '../../../scss/images/icon-top-2.svg';
import { FunctionComponent } from 'react';
import { useConfig, useGetStatistics } from '~frontend/components/hooks';
import { useTranslation } from 'react-i18next';

const Online = () => {
  const { data: { getStatistics } = {} } = useGetStatistics();
  //console.log('getStatistics11111');
  return <>{getStatistics?.online}</>;
};

export const HeaderTop: FunctionComponent = () => {
  const { t } = useTranslation();
  const getConfig = useConfig();

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  //console.log('isMobil1', isMobile);

  return (
    <div className="header_top">
      {!isMobile && (
        <div className="header_online">
          <img
            className="header_online_img"
            src={icon_online}
            alt="online"
            width={21}
            height={21}
          />
          <span className="header_online_count">
            <Online />
          </span>
          <span className="header_online_text">{t('Online')}</span>
        </div>
      )}

      {/* <div className="header_top_change">
        <div className="header_top_change_item">
          <img src={icon_top} alt="arrow" width={24} height={24} />
        </div>
        <div className="header_top_change_item">
          <img src={icon_top2} alt="arrow" width={24} height={24} />
        </div>
      </div> */}
      <TopDrop />
    </div>
  );
};
