import { FunctionComponent, useEffect, useMemo } from 'react';
import { Game as GameType } from '~types';
import { gql, useSubscription } from '@apollo/client';
import _ from 'lodash';
import { createGlobalState, useDeepCompareEffect } from 'react-use';
import { useToggle, fragments } from './';
import Game from './Game';

export const useNewGamesList = createGlobalState<GameType[]>([]);
export const useNewGame = createGlobalState<GameType>();
export const useTopDrop = createGlobalState<GameType>();

interface SubscribeData {
  newGame: GameType;
}

export const NewGamesList: FunctionComponent = () => {
  const [isBest] = useToggle();
  const [getGames, setGames] = useNewGamesList();
  const [getGame, setNewGame] = useNewGame();

  useSubscription<SubscribeData>(
    gql`
      subscription newGame {
        newGame {
          ...LiveGameFields
        }
      }
      ${fragments.games}
    `,
    {
      onData({ data }) {
        const getGame = data?.data?.newGame;

        if (!getGame || getGame?.getUser?.isHidden) {
          return;
        }

        if (isBest) {
          if ([3].includes(getGame?.getCase?.type)) {
            return;
          }

          if (
            getGame?.price < getGame?.gamePrice ||
            ['4b69ff', '5e98d9', 'b0c3d9'].includes(getGame?.getItem?.color)
          ) {
            return;
          }
        }

        console.log('New game received:', new Date().toLocaleTimeString()); // Debugging
        setNewGame(getGame);
      },
    },
  );

  useDeepCompareEffect(() => {
    console.log('New game added to list:', new Date().toLocaleTimeString()); // Debugging
    setGames((getGames) => _.take([getGame, ...getGames], 30));
  }, [getGame || {}]);

  useEffect(() => {
    setGames([]);
  }, [isBest]);

  const games = useMemo(() => {
    return _.map(getGames, (getGame) => (
      <Game key={getGame.id} game={getGame} />
    ));
  }, [getGames]);

  return <>{games}</>;
};
