import {
  memo,
  useCallback,
  FunctionComponent,
  SyntheticEvent,
  useMemo,
} from 'react';
import item from '../top-drop/item.png';
import ava from '../top-drop/ava.png';
import drop_case from '../top-drop/drop-case.png';
import { Game as GameType } from '~types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useOpenModal } from '~components/modals/hooks';
import { GetName, SteamItemImage } from '~components/item';
import PlaceholderItem from '../../../scss/images/breezy-bg-empty-item.svg?react';
import PlaceholderItemImg from '../../../scss/images/breezy-bg-empty-item.svg';

import playAudio from '~frontend/utils/playAudio';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import { useTranslation } from 'react-i18next';
import Time from './Time';
import { useConfig } from '~frontend/components/hooks';
import DropVip from '../top-drop/drop-vip';
import '../top-drop/topDrop.scss';

interface LiveGameProps {
  game: GameType;
  isTop?: boolean;
}

const Game: FunctionComponent<LiveGameProps> = ({
  game: {
    chance,
    type,
    getUser,
    getItem,
    getCase: { getImage, getUrl, getName },
    id,
    createdAt,
    distributionGameId,
    battlePassId,
  },
  isTop,
}) => {
  const getConfig = useConfig();
  const { onShow } = useOpenModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onMouseEnterHandler = useCallback((event: SyntheticEvent) => {
    playAudio(event);
  }, []);

  // Объект с сопоставлением цветов и названий классов
  const rarityColors = {
    b0c3d9: 'top_drop_item_consumer',
    '4b69ff': 'top_drop_item_military',
    '5e98d9': 'top_drop_item_industrial',
    '8847ff': 'top_drop_item_restricted',
    d32ce6: 'top_drop_item_classified',
    eb4b4b: 'top_drop_item_covert',
    ffc600: 'top_drop_item_legendary',
  };

  // Функция для получения названия класса по цвету
  const getRarityClassName = useCallback((color) => {
    return rarityColors[color] || '';
  }, []);

  const rarityClassName = useMemo(
    () => getRarityClassName(getItem.color),
    [getItem.color, getRarityClassName],
  );

  const generateProfileLink = useMemo(() => {
    const link = `/user/${getUser.id}`;
    return link;
  }, [getUser.id]);

  const generateCaseLink = useMemo(() => {
    const link = `${getUrl}`;
    return link;
  }, [getUrl]);

  // Обработчик клика по аватару для перехода на страницу пользователя
  const goProfilePage = useCallback(() => {
    navigate(generateProfileLink);
  }, [navigate, generateProfileLink]);

  const goCasePage = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      // if (_.get(event.target, 'classList', false)) {
      //   if ((event.target as Element).classList.contains('ava')) {
      //     return;
      //   }
      // }

      //event.preventDefault();

      const { url } = event.currentTarget.dataset;

      // console.log('url', url);
      // console.log('urlCase', generateCaseLink);

      if (distributionGameId && getConfig.seo.distributionActive) {
        event.stopPropagation();
        return onShow('DistributionWinModal', {
          id: distributionGameId,
        });
      }

      navigate(generateCaseLink);
    },
    [navigate],
  );

  const showChance = useMemo(() => {
    if (!chance) {
      return null;
    } else if (getConfig?.isRU && chance > 5) {
      return null;
    }

    return <div className="top_drop_item_view_percent">{chance}%</div>;
  }, [chance]);

  return (
    <>
      <div
        onMouseEnter={onMouseEnterHandler}
        data-url={getUrl}
        data-game={id}
        data-type={type}
        data-rar={getItem.color}
        data-audio="5.wav"
        data-volume="0.4"
        className={`top_drop_item ${
          isTop ? 'top_drop_item_vip' : ''
        } ${rarityClassName}`}
      >
        {isTop && <DropVip className="top_drop_item_vip_icon" />}
        <div className="top_drop_item_view">
          {/* <img
            className="top_drop_item_view_image"
            src={item.src}
            alt="item"
            width={108}
            height={82}
          /> */}
          <SteamItemImage
            url={getItem.getImage}
            size="108x82"
            alt={getItem.getName}
            width={108}
            height={82}
          />
          <div className="top_drop_item_view_content">
            {showChance}
            <div className="top_drop_item_view_name">
              {getItem?.getName.split('|')[1].trim()}
            </div>
          </div>
        </div>
        <div className="top_drop_item_view_hover">
          <div onClick={goProfilePage} className="top_drop_item_view_hover_ava">
            <LazyLoadImage
              src={getUser?.avatar}
              alt="ava"
              width={32}
              height={32}
            />
            <span>{getUser?.userName}</span>
          </div>
          <div className="top_drop_item_view_hover_case" onClick={goCasePage}>
            <LazyLoadImage
              src={getImage}
              alt={getName}
              width={60}
              height={60}
            />
          </div>
        </div>
      </div>

      {/* <div
        onMouseEnter={onMouseEnterHandler}
        onClick={goCasePage}
        data-url={getUrl}
        data-game={id}
        data-type={type}
        className="top-drop-item"
        data-rar={getItem.color}
        data-audio="5.wav"
        data-volume="0.4"
      >
        {showChance}
        {getItem.isStatTrak && (
          <div className="stattrack">
            <span className="fas fa-star" /> StatTrak™
          </div>
        )}
        <div className="top-drop-item-first"></div>
        <div className="honey"></div>

        <div className="drop-img">
          <SteamItemImage
            url={getItem.getImage}
            size="200x200"
            alt={getItem.getName}
            width={200}
            height={200}
          />
        </div>

        {type === 1 ? (
          <div className="top-drop-item-other-contracts top-drop-game-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <g transform="translate(1611 1395) rotate(180)">
                <g
                  transform="translate(1579 1363)"
                  fill="#fff"
                  stroke="#707070"
                  strokeWidth="1"
                  opacity="0"
                >
                  <rect width="32" height="32" stroke="none"></rect>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="31"
                    height="31"
                    fill="none"
                  ></rect>
                </g>
                <path
                  d="M-64,4478h-2v-2a9.871,9.871,0,0,1-6.2-2.8A9.869,9.869,0,0,1-75,4467h-2v-2h2a9.869,9.869,0,0,1,2.8-6.2A9.875,9.875,0,0,1-66,4456v-2h2v2a9.876,9.876,0,0,1,6.2,2.8A9.868,9.868,0,0,1-55,4465h2v2h-2a9.872,9.872,0,0,1-2.8,6.2A9.876,9.876,0,0,1-64,4476v2Zm0-5v1a7.509,7.509,0,0,0,7-7h-1v-2h1a7.51,7.51,0,0,0-7-7v1h-2v-1a7.507,7.507,0,0,0-7,7h1v2h-1a7.5,7.5,0,0,0,7,7v-1Zm-1-2a5.2,5.2,0,0,1-5-5,5.2,5.2,0,0,1,5-5,5.2,5.2,0,0,1,5,5A5.2,5.2,0,0,1-65,4471Zm0-8a3,3,0,0,0-3,3,3,3,0,0,0,3,3,3,3,0,0,0,3-3A3,3,0,0,0-65,4463Z"
                  transform="translate(1660 -3087)"
                  fill="#fff"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                ></path>
              </g>
            </svg>
          </div>
        ) : type === 2 ? (
          <div className="top-drop-item-other-upgrade top-drop-game-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.66671 10.3334H5.33337V7.83337H2.83337L7.00004 2.83337L11.1667 7.83337H8.66671V10.3334Z"
                fill="white"
              ></path>
            </svg>
          </div>
        ) : null}

        <div className="top-drop-item-hover">
          <div className="top-drop-item-ago">
            <Time createdAt={createdAt} />
          </div>
          <div
            className="top-drop-item-name"
            data-game={id}
            data-url={getUrl}
            onClick={goCasePage}
          >
            <GetName getItem={getItem} type={1} />
          </div>
          <Link
            to={generateProfileLink}
            className="top-drop-item-ava"
            rel="nofollow"
          >
            <LazyLoadImage
              className="ava"
              src={getUser.avatar}
              alt={getUser.userName}
            />
            <div className="tooltip-block">{t('To the profile')}</div>
          </Link>
          <Link to={getUrl} className="top-drop-item-case">
            <LazyLoadImage
              className="icon-drop-case"
              src={getImage}
              alt={getName}
            />
            <div className="tooltip-block">
              {t(`top-drop-item-case data type ${type}`, {
                distributionGameId,
                battlePassId,
              })}
            </div>
          </Link>
        </div>
      </div> */}
    </>
  );
};

export default memo(Game);
