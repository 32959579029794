export default function menuUpgrade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm-2 11v5h4v-5h4l-6-7-6 7h4Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
