import _ from 'lodash';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Currency from '~components/Currency';
import { useConfig, useTimer } from '~components/hooks';
import { GetName, SteamItemImage } from '~components/item';
import { useOpenModal } from '~components/modals/hooks';
import { DistributionGame as DistributionGameType } from '~types';
import { Price } from '~ui/index';
import Check from './check';
import Clock from './clock';
import online from '../../../scss/images/online.png';
import './distribution.scss';

interface Props {
  getDistributionGame: DistributionGameType;
  isModal?: boolean;
  onClose?: any;
}

interface ITimerProps {
  endDate: string;
}

const Timer: FunctionComponent<ITimerProps> = memo(({ endDate }) => {
  const timer = useTimer(endDate);
  const { t } = useTranslation();

  return (
    <>
      {' '}
      {timer?.days > 0 && (
        <span className="day">
          {timer?.days} {t('d')}.
        </span>
      )}
      <span>
        {timer?.hours} : {timer?.minutes} : {timer?.seconds}
      </span>
    </>

    // <div className="distribution-item-controls-timer">
    //   <div className="icon material-icons">&#xe8b5;</div>

    // </div>
  );
});

export const Distribution: FunctionComponent<Props> = memo(
  ({ getDistributionGame, isModal, onClose }) => {
    const { onShow } = useOpenModal();
    const getConfig = useConfig();
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const getItem = getDistributionGame?.getItem;

    const onClick = () => {
      onShow('DistributionModal', {
        id: getDistributionGame?.id,
      });
      onClose();
    };

    const rarities: string[] = [
      'b0c3d9',
      '4b69ff',
      '5e98d9',
      '8847ff',
      'd32ce6',
      'eb4b4b',
    ];

    const rarityClass = getItem?.color
      ? `rarity-${rarities.findIndex((color) => color === getItem.color) + 1}`
      : '';

    return (
      <div
        //добавил isModal чтобы для гивов которые отображаются в модалке ширина была 100% через импортант, а не 25%
        className={`giveaways_item ${rarityClass} ${
          isModal ? 'giveaways_item1' : ''
        }`}
        // data-rar={getItem?.color}
        onClick={onClick}
      >
        <div className="giveaways_item_image">
          <SteamItemImage url={getItem?.getImage} size="128x100" />
        </div>
        <div className="giveaways_item_info">
          <div className="giveaways_item_info_about">
            {t(getDistributionGame?.getType)}
            {getDistributionGame?.getDistribution?.conditions?.user
              ?.depositSum === 0 && ' FREE'}
          </div>
          <div className="giveaways_item_info_name">
            <GetName getItem={getItem} type={0} />
          </div>
          <div className="giveaways_item_info_category">
            <GetName getItem={getItem} type={1} />
          </div>
          <div className="giveaways_item_info_wrap">
            <div className="giveaways_item_info_timer">
              <Clock />
              <Timer endDate={getDistributionGame?.endDate} />
            </div>
            <div className="giveaways_item_info_count">
              <img src={online} width={14} height={14} />
              <span>{getDistributionGame?.usersCount}</span>
            </div>
            {getDistributionGame?.isJoin && (
              <div className="giveaways_item_info_check">
                <Check />
              </div>
            )}
          </div>
        </div>
      </div>

      // <div
      //   className="distribution-item"
      //   data-rar={getItem?.color}
      //   onClick={onClick}
      // >
      //   <div className="distribution-item-bg"></div>
      //   <div className={`distribution-item-content`}>
      //     <div className="distribution-item-num">
      //       {t(getDistributionGame?.getType)}
      //       {getDistributionGame?.getDistribution?.conditions?.user
      //         ?.depositSum === 0 && ' FREE'}
      //     </div>
      //     <div className="distribution-item-name">
      //       <GetName getItem={getItem} type={0} />
      //     </div>
      //     <div className={`distribution-item-title`}>
      //       <GetName getItem={getItem} type={1} />
      //     </div>
      //     {/* <div className="distribution-item-left">
      //       <div className="distribution-item-left-name">
      //         {t(
      //           'distribution-item distribution-item-left distribution-item-left-name',
      //         )}
      //       </div>
      //       <div className="distribution-item-left-donat">
      //         {_.get(
      //           getDistributionGame?.getDistribution,
      //           `description[${language}]`,
      //         )}
      //       </div>
      //     </div> */}
      //     <div className="distribution-item-controls">
      //       <div className="distribution-item-controls-counter">
      //         <div className="icon material-icons">group</div>
      //         <span>{getDistributionGame?.usersCount}</span>
      //       </div>
      //       <Timer endDate={getDistributionGame?.endDate} />
      //       {getDistributionGame?.isJoin && (
      //         <div className="distribution-item-controls-part">
      //           <div className="icon material-icons">check</div>
      //           <span>
      //             {t('distribution-item distribution-item-controls-part span')}
      //           </span>
      //         </div>
      //       )}
      //     </div>
      //     <div className="distribution-item-controls-cost">
      //       {getConfig.isEU ? (
      //         <>
      //           <Currency />
      //           <Price sum={getDistributionGame?.price} />
      //         </>
      //       ) : (
      //         <>
      //           <Price sum={getDistributionGame?.price} />
      //           <Currency />
      //         </>
      //       )}
      //     </div>
      //   </div>
      //   <div className="distribution-item-img">
      //     <SteamItemImage url={getItem?.getImage} size="200x200" />
      //   </div>
      // </div>
    );
  },
);
