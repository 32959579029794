export default function menuWheel(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.503 1.376h4l-2.001 4.251-2-4.25Zm5.33 11.042a3.334 3.334 0 0 1-5.706 2.283A3.333 3.333 0 0 1 12.5 9.03a3.36 3.36 0 0 1 3.332 3.387Zm-1.332-.677h-1.333v-1.355h-1.333v1.355h-1.333v1.355h1.333v1.355h1.333v-1.355H14.5V11.74Zm-2 10.883a10.069 10.069 0 0 1-9.906-8.826A10.182 10.182 0 0 1 9.86 2.628l1.222 2.482a7.342 7.342 0 0 0-3.514 12.645 7.342 7.342 0 0 0 10.825-9.822A7.342 7.342 0 0 0 13.92 5.11l1.221-2.482a10.18 10.18 0 0 1 7.264 11.17 10.072 10.072 0 0 1-9.904 8.826ZM16.187 9.59l.933-.959a6.126 6.126 0 0 1 1.252 3.111h-1.32a4.772 4.772 0 0 0-.866-2.152ZM13.16 17.1a4.505 4.505 0 0 0 2.093-.89l.933.959a5.784 5.784 0 0 1-3.026 1.287V17.1Zm0-9.359V6.637l.116-.239a5.792 5.792 0 0 1 2.91 1.276l-.934.96a4.506 4.506 0 0 0-2.094-.892h.002Zm-3.413 8.47c.61.47 1.33.777 2.093.89v1.356a5.788 5.788 0 0 1-3.027-1.287l.934-.96Zm-.933-8.54a5.797 5.797 0 0 1 2.91-1.275l.115.24V7.74a4.505 4.505 0 0 0-2.093.89l-.932-.96ZM6.63 13.1h1.32c.107.778.404 1.517.866 2.152l-.933.96A6.128 6.128 0 0 1 6.63 13.1v-.001Zm0-1.357a6.127 6.127 0 0 1 1.252-3.11l.933.958a4.775 4.775 0 0 0-.866 2.152h-1.32ZM18.372 13.1a6.126 6.126 0 0 1-1.252 3.11l-.933-.959a4.771 4.771 0 0 0 .866-2.152h1.32-.001Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
