import MenuCase from './menu-case';
import MenuWheel from './menu-wheel';
import MenuUpgrade from './menu-upgrade';
import MenuContracts from './menu-contracts';
import MenuVip from './menu-vip';
import { Link } from 'react-router-dom';

import './menu.scss';
import { useProfile } from '~frontend/components/hooks';
import { useTranslation } from 'react-i18next';

export default function Menu() {
  const { t } = useTranslation();
  const getProfile = useProfile();

  const onClick = () => {
    if (window.jivo_api) {
      document.querySelector('jdiv').className = 'active';
      window.jivo_api.open();
    }
  };
  return (
    <nav className="menu">
      <Link to="/" className="menu_item">
        <MenuCase />
        <span className="menu_item_text">Cases</span>
      </Link>
      <Link to="/wheel" className="menu_item">
        <MenuWheel />
        <span className="menu_item_text">{t('Bonus_Wheel')}</span>
      </Link>
      <Link
        to="/upgrade"
        className="menu_item"
        style={{ opacity: 0.48, cursor: 'default', pointerEvents: 'none' }}
        onClick={(e) => e.preventDefault()}
      >
        <MenuUpgrade />
        <span className="menu_item_text">{t('Upgrades')}</span>
      </Link>
      <Link
        to="/contract"
        className="menu_item"
        style={{ opacity: 0.48, cursor: 'default', pointerEvents: 'none' }}
        onClick={(e) => e.preventDefault()}
      >
        <MenuContracts />
        <span className="menu_item_text">{t('Contracts')}</span>
      </Link>
      {/* <Link to="/" className="menu_item">
        <MenuVip />
        <span className="menu_item_text">VIP club</span>
      </Link> */}
    </nav>
  );
}
