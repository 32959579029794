import styles from './style.module.scss';
import item from './item.png';
import ava from './ava.png';
import drop_case from './drop-case.png';
import DropVip from './drop-vip';

import './topDrop.scss';
import { Game as GameType } from '~types';
import { FunctionComponent, useMemo } from 'react';
import { useQuery, gql, DocumentNode } from '@apollo/client';

import {
  createGlobalState,
  useUpdateEffect,
  useDeepCompareEffect,
} from 'react-use';

import _ from 'lodash';
import update from 'immutability-helper';
import Game from '../live/Game';
import { NewGamesList } from '../live/NewGamesList';
import Live from '../live';

export const useToggle = createGlobalState<boolean>(true);
export const useNewGamesList = createGlobalState<GameType[]>([]);
export const useNewGame = createGlobalState<GameType>();
export const useTopDrop = createGlobalState<GameType>();

export const FRAGMENT_GAME_FILEDS = gql`
  fragment LiveGameFields on Game {
    id
    type
    userId
    chance
    price
    createdAt
    gamePrice
    type
    distributionGameId
    battlePassId
    eventId
    getUser {
      id
      userName
      avatar
      isHidden
    }
    getItem {
      id
      getImage
      name
      name_en
      market_hash_name
      getName
      color
      isStatTrak
    }
    getCase {
      id
      getImage
      getUrl
      type
    }
  }
`;

type FragmentsTypes = {
  games: DocumentNode;
};

export const fragments: FragmentsTypes = {
  games: FRAGMENT_GAME_FILEDS,
};

const GET_LIVE_GAMES = gql`
  query Live($isBest: Boolean!) {
    getLiveGames(isBest: $isBest) {
      ...LiveGameFields
    }
  }

  ${FRAGMENT_GAME_FILEDS}
`;

interface LiveGameVars {
  isBest: boolean;
}

interface LiveGameData {
  getLiveGames: GameType[];
}

const GameList: FunctionComponent = () => {
  const [topDrop, setTopDrop] = useTopDrop();
  const [isBest] = useToggle();
  const [getGame] = useNewGame();

  const { loading, error, data, refetch, client } = useQuery<
    LiveGameData,
    LiveGameVars
  >(GET_LIVE_GAMES, {
    variables: {
      isBest,
    },
    ssr: false,
    onCompleted: ({ getLiveGames }) => {
      setTopDrop(_.first(_.orderBy(getLiveGames, ['price'], ['desc'])));
    },
  });

  const getLiveGames = useMemo(() => {
    return _.map(data?.getLiveGames, (game) => (
      <Game key={game.id} game={game} />
    ));
  }, [data?.getLiveGames]);

  useUpdateEffect(() => {
    refetch();
  }, [isBest]);

  useDeepCompareEffect(() => {
    if (getGame) {
      client.cache.writeQuery({
        query: GET_LIVE_GAMES,
        variables: {
          isBest,
        },
        data: update(data, {
          getLiveGames: {
            $splice: [[_.size(data.getLiveGames), 1]],
          },
        }),
      });

      if (getGame?.price > topDrop?.price) {
        setTopDrop(getGame);
      }
    }
  }, [getGame || {}]);

  if (loading) {
    return <>Loading...</>;
  } else if (error) {
    return <>Live | Error! {error.message}</>;
  }

  return <>{getLiveGames}</>;
};

export const TopDrop: FunctionComponent = () => {
  return (
    <Live />
    // <div className="top_drop">
    //   {/* <div className="top_drop_item top_drop_item_consumer top_drop_item_vip">
    //     <DropVip className="top_drop_item_vip_icon" />
    //     <div className="top_drop_item_view">
    //       <img
    //         className="top_drop_item_view_image"
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className="top_drop_item_view_content">
    //         <div className="top_drop_item_view_percent">17,24%</div>
    //         <div className="top_drop_item_view_name">Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className="top_drop_item_view_hover">
    //       <div className="top_drop_item_view_hover_ava">
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className="top_drop_item_view_hover_case">
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div> */}
    //   <NewGamesList />
    //   <GameList />
    //   {/* <div className="top_drop_item top_drop_item_military top_drop_item_vip">
    //     <DropVip className="top_drop_item_vip_icon" />
    //     <div className="top_drop_item_view">
    //       <img
    //         className="top_drop_item_view_image"
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className="top_drop_item_view_content">
    //         <div className="top_drop_item_view_percent">17,24%</div>
    //         <div className="top_drop_item_view_name">Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className="top_drop_item_view_hover">
    //       <div className="top_drop_item_view_hover_ava">
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className="top_drop_item_view_hover_case">
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="top_drop_item top_drop_item_industrial">
    //     <div className="top_drop_item_view">
    //       <img
    //         className="top_drop_item_view_image"
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className="top_drop_item_view_content">
    //         <div className="top_drop_item_view_percent">17,24%</div>
    //         <div className="top_drop_item_view_name">Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className="top_drop_item_view_hover">
    //       <div className="top_drop_item_view_hover_ava">
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className="top_drop_item_view_hover_case">
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="top_drop_item top_drop_item_restricted">
    //     <div className="top_drop_item_view">
    //       <img
    //         className="top_drop_item_view_image"
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className="top_drop_item_view_content">
    //         <div className="top_drop_item_view_percent">17,24%</div>
    //         <div className="top_drop_item_view_name">Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className="top_drop_item_view_hover">
    //       <div className="top_drop_item_view_hover_ava">
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className="top_drop_item_view_hover_case">
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_classified}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className={`${styles.top_drop_item} ${styles.top_drop_item_covert}`}>
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_legendary}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_consumer}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_military}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_industrial}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_restricted}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_classified}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className={`${styles.top_drop_item} ${styles.top_drop_item_covert}`}>
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_legendary}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_consumer}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_military}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_industrial}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_restricted}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_classified}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div className={`${styles.top_drop_item} ${styles.top_drop_item_covert}`}>
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className={`${styles.top_drop_item} ${styles.top_drop_item_legendary}`}
    //   >
    //     <div className={styles.top_drop_item_view}>
    //       <img
    //         className={styles.top_drop_item_view_image}
    //         src={item.src}
    //         alt="item"
    //         width={108}
    //         height={82}
    //       />
    //       <div className={styles.top_drop_item_view_content}>
    //         <div className={styles.top_drop_item_view_percent}>17,24%</div>
    //         <div className={styles.top_drop_item_view_name}>Hyper Beast</div>
    //       </div>
    //     </div>
    //     <div className={styles.top_drop_item_view_hover}>
    //       <div className={styles.top_drop_item_view_hover_ava}>
    //         <img src={ava.src} alt="ava" width={32} height={32} />
    //         <span>Immortal</span>
    //       </div>
    //       <div className={styles.top_drop_item_view_hover_case}>
    //         <img src={drop_case.src} alt="drop_case" width={60} height={60} />
    //       </div>
    //     </div>
    //   </div> */}
    // </div>
  );
};
