export default function menuCase(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.5 4.5a.5.5 0 0 1 1 0V5h-1v-.5ZM16.5 4.5a.5.5 0 0 1 1 0V5h-1v-.5Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.5 7a2 2 0 0 0-2-2h-18a2 2 0 0 0-2 2v2H5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H1.5V18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-7.5H20a.5.5 0 0 1-.5-.5v-.5A.5.5 0 0 1 20 9h3.5V7ZM8 8a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5v-4A.5.5 0 0 1 7 8h1Zm10.5.5A.5.5 0 0 0 18 8h-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4Zm-9 1A.5.5 0 0 1 10 9h5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#2B2B36"
        d="M3.9 19.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v.5h-2v-.5ZM10.9 19.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v.5h-3v-.5ZM18.9 19.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v.5h-2v-.5Z"
      />
    </svg>
  );
}
