export default function menuContracts(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Zm-.97-14.753a1 1 0 0 0 1.94 0 4.855 4.855 0 0 1 3.783 3.784 1 1 0 0 0 0 1.938 4.855 4.855 0 0 1-3.784 3.784 1 1 0 0 0-1.938 0 4.855 4.855 0 0 1-3.784-3.784 1 1 0 0 0 0-1.938 4.855 4.855 0 0 1 3.784-3.784Zm1.97-2.176V4a1 1 0 1 0-2 0v1.07A7.005 7.005 0 0 0 5.57 11H4.5a1 1 0 1 0 0 2h1.07a7.004 7.004 0 0 0 5.93 5.93V20a1 1 0 1 0 2 0v-1.07A7.004 7.004 0 0 0 19.43 13h1.07a1 1 0 1 0 0-2h-1.07a7.004 7.004 0 0 0-5.93-5.93ZM12.5 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-1.987a1.013 1.013 0 1 1 0-2.026 1.013 1.013 0 0 1 0 2.026Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
