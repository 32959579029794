import './headerBottom.scss';

import {
  useEffect,
  useRef,
  useState,
  memo,
  MouseEventHandler,
  FunctionComponent,
  SyntheticEvent,
  useCallback,
} from 'react';
import logo from '../../../scss/images/logoNew.svg';
import logo_mob from '../../../scss/images/logo-mob.svg';
// import Menu from '../menu';
import Login from './login';
import Partner from './partner';
// import lang from '@/app/images/icon-flag-ua.svg';
// import ava_profile from '@/app/images/ava-profile.png';
import MenuIcon from './menu';
import Quantity from './icon-quantity';
import Add from './add';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Menu from '../menu/menu';
import MenuMob from '../menu-mob/menu-mob';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useConfig, useProfile } from '~frontend/components/hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '~shared/frontend/ui/button/button';
import { Price } from '~shared/frontend/ui';
import Currency from '~frontend/components/Currency';
import { Languages } from '../Languages';
import { useClickAway, useToggle } from 'react-use';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import _ from 'lodash';
import LanguageModal from '~frontend/components/modals/language-modal/languageModal';
import SignIn from '~frontend/components/modals/sign-in/signIn';
import Support from '../../../scss/images/supportWhite.svg';
import SupportWhite from './supportWhite';

const langImage = {
  ru: require(`../../../scss/images/ru.svg`),
  en: require(`../../../scss/images/en.svg`),
  de: require(`../../../scss/images/de.svg`),
  tr: require(`../../../scss/images/tr.svg`),
  pl: require(`../../../scss/images/pl.svg`),
  pt: require(`../../../scss/images/pt.svg`),
  th: require(`../../../scss/images/th.svg`),
  hi: require(`../../../scss/images/hi.svg`),
};

interface LangProps {
  key: string;
  lang: string;
  setLanguage: MouseEventHandler<HTMLDivElement>;
  language: string;
}

const Lang: FunctionComponent<LangProps> = memo(
  ({ lang, setLanguage, language }) => (
    <div
      data-lang={lang}
      className={`header__controls-lang-dropdown-item ${
        lang === language && 'active'
      }`}
      onClick={setLanguage}
    >
      <LazyLoadImage className="lang-icon" src={langImage[lang]} alt="" />
      <span>{lang}</span>
    </div>
  ),
);

const handleSupportClick = () => {
  if (window.jivo_api) {
    document.querySelector('jdiv').className = 'active';
    window.jivo_api.open();
  }
};

export default function HeaderBottom() {
  const ref = useRef(null);
  const yametrica = useYaMetrica();
  const getConfig = useConfig();
  const getProfile = useProfile();
  const { t, i18n } = useTranslation();
  const [, setCookie] = useCookies();
  const { pathname } = useLocation();
  const { reFetchObservableQueries } = useApolloClient();
  const navigate = useNavigate();
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const languages = i18n.languages;

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const openLanguageModal = (e) => {
    e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    setIsLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setIsLanguageModalOpen(false);
  };

  const closeSignIn = () => {
    setIsSignInOpen(false);
  };

  const handleLogin = (e) => {
    // Проверяем, есть ли событие e, и только потом вызываем stopPropagation
    if (e && e.stopPropagation) {
      e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    }

    // Просто устанавливаем флаг открытия формы входа
    setIsSignInOpen(true);

    // Не закрываем мобильное меню при открытии формы,
    // иначе toggleMobileMenu закроет все, включая форму входа
    // if (onClose) {
    //   onClose();
    // }
  };

  // const [active, setActive] = useToggle(false);

  // useClickAway(ref, () => {
  //   setActive(false);
  // });

  // useEffect(() => {
  //   setActive(false);
  // }, [i18n.language, setActive]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!i18n.language) {
      console.warn('i18n language is not set');
    }
  }, [i18n.language]);

  return (
    <>
      {' '}
      <div className="header_bottom">
        <Link to="/" className="header_logo">
          <LazyLoadImage src={logo} alt="logo" width={197} height={56} />
        </Link>
        <Link to="/" className="header_logo_mob">
          <LazyLoadImage src={logo_mob} alt="logo" width={44} height={32} />
        </Link>
        <Menu />
        <MenuMob />
        <div className="header_bottom_right">
          {getProfile ? (
            <>
              {getConfig?.getBattlePass && (
                <Button
                  className="btn1_blue btn1_blue_bordered"
                  text={<Price sum={getProfile?.battlePassCoins} />}
                  icon={<Quantity />}
                />
              )}
              <Button
                className="btn1_yellow"
                text={
                  getConfig.isEU ? (
                    <>
                      <Currency />
                      <Price sum={getProfile?.money} />
                    </>
                  ) : (
                    <>
                      <Currency />
                      <Price sum={getProfile?.money} />
                    </>
                  )
                }
                icon={<Add />}
                href="/pay"
                icon_direction="right"
              />
              {!isMobile && (
                <Button
                  className="btn1_blue"
                  text="Partner"
                  href="/referral"
                  icon={<Partner />}
                />
              )}
              {
                <Button
                  className={`header_lang`}
                  onClick={handleSupportClick}
                  icon={<SupportWhite />}
                ></Button>
              }
            </>
          ) : (
            <Button
              className="btn1_yellow"
              text={t('Login')}
              icon={<Login />}
              type={'button'}
              onClick={handleLogin}
            />
          )}
          <div className="header_lang" onClick={openLanguageModal}>
            <div className="header_lang_select">
              <LazyLoadImage
                src={langImage[i18n.language]}
                alt="current_lang"
                width={24}
                height={24}
              />
            </div>
          </div>
          {isLanguageModalOpen && (
            <LanguageModal onClose={closeLanguageModal} />
          )}
          {getProfile && (
            <Link to="/profile" className="header_profile">
              <LazyLoadImage
                src={getProfile?.avatar}
                alt="avatar"
                width={44}
                height={44}
              />
            </Link>
          )}
          {/* <Link to="/" className="header_menu_dropdown">
            <MenuIcon />
          </Link> */}
        </div>
      </div>
      {isSignInOpen && <SignIn onClose={closeSignIn} />}
    </>
  );
}
