import Currency from '~frontend/components/Currency';
import './dropItem.scss';
import { Price } from '~shared/frontend/ui';
import { useCallback, useMemo } from 'react';
import { SteamItemImage } from '~frontend/components/item';

export default function DropItem({
  price,
  percent,
  imageSrc,
  tag,
  name,
  condition,
  variant,
  getItem,
}) {
  // Объект с сопоставлением цветов и названий классов
  const rarityColors = {
    b0c3d9: 'drop_item_consumer',
    '4b69ff': 'drop_item_military',
    '5e98d9': 'drop_item_industrial',
    '8847ff': 'drop_item_restricted',
    d32ce6: 'drop_item_classified',
    eb4b4b: 'drop_item_covert',
    ffc600: 'drop_item_legendary',
  };

  // Функция для получения названия класса по цвету
  const getRarityClassName = useCallback((color) => {
    return rarityColors[color] || '';
  }, []);

  const rarityClassName = useMemo(
    () => getRarityClassName(getItem?.color),
    [getItem?.color, getRarityClassName],
  );
  return (
    <div className={`drop_item ${variant ? `${rarityClassName}` : ''}`}>
      <div className="drop_item_header">
        <div className="drop_item_price">
          <Currency /> {price}
        </div>
        {/* <div className="drop_item_percent">{percent}%</div> */}
      </div>
      <SteamItemImage
        url={getItem?.getImage}
        size="154x116"
        alt="prize"
        width={154}
        height={116}
        className="drop_item_image"
      />
      <div className="drop_item_info">
        <div className="drop_item_tag">{tag}</div>
        <div className="drop_item_name">
          {condition && <span>{condition}</span>} | {name}
        </div>
      </div>
    </div>
  );
}
