export default function MenuMob3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.8 5.267v5.066h-2V5.267c0-.36-.32-.534-.533-.534a.535.535 0 0 0-.2.04L6.493 8.76a1.791 1.791 0 0 0-1.16 1.693v.894c-1.213.906-2 2.36-2 4v-4.894c0-1.586.974-3 2.454-3.56l10.586-4c.294-.106.6-.16.894-.16 1.333 0 2.533 1.08 2.533 2.534ZM28.667 19.333v1.334c0 .36-.28.653-.653.666h-1.947c-.707 0-1.347-.52-1.4-1.213-.04-.413.12-.8.387-1.067.24-.253.573-.386.933-.386H28a.668.668 0 0 1 .667.666Z"
      />
      <path
        fill="currentColor"
        d="M25.973 17.267h1.36c.734 0 1.334-.6 1.334-1.334v-.586a5.025 5.025 0 0 0-5.014-5.014H8.347a4.942 4.942 0 0 0-3.014 1.014c-1.213.906-2 2.36-2 4v8.973a5.025 5.025 0 0 0 5.014 5.013h15.306a5.025 5.025 0 0 0 5.014-5.013v-.253c0-.734-.6-1.334-1.334-1.334h-1.16c-1.28 0-2.506-.786-2.84-2.026a2.664 2.664 0 0 1 .72-2.64 2.657 2.657 0 0 1 1.92-.8ZM18.667 17H9.333c-.546 0-1-.453-1-1 0-.547.454-1 1-1h9.334c.546 0 1 .453 1 1 0 .547-.454 1-1 1Z"
      />
    </svg>
  );
}