export default function ArrowRight({ className, ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M9.28125 6.70312C9.70312 6.32812 10.3125 6.32812 10.6875 6.70312L15.2812 11.2969C15.7031 11.6719 15.7031 12.3281 15.2812 12.7031L10.6875 17.2969C10.3125 17.6719 9.70312 17.6719 9.28125 17.2969C8.90625 16.9219 8.90625 16.2656 9.28125 15.8906L13.1719 12L9.28125 8.10938C8.90625 7.73438 8.90625 7.07812 9.28125 6.70312Z"
        fill="#6C6F8B"
      />
    </svg>
  );
}
