import {
  FunctionComponent,
  memo,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import MenuMob1 from './menu-mob-1';
import MenuMob2 from './menu-mob-2';
import MenuMob3 from './menu-mob-3';
import MenuMob4 from './menu-mob-4';
import MenuMob5 from './menu-mob-5';
import './menu-mob.scss';
import MenuWheel from '../menu/menu-wheel';
import MobileMenuModal from './mobile-menu-modal/MobileMenuModal';
import { useProfile } from '~frontend/components/hooks';
import Login from '../header-bottom/login';
import SignIn from '~frontend/components/modals/sign-in/signIn';
import { Button } from '~shared/frontend/ui/button/button';
import { useOpenModal } from '~frontend/components/modals/hooks';

const MenuMob: FunctionComponent = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const getProfile = useProfile();
  const { t } = useTranslation();
  const { onShow } = useOpenModal();

  const toggleMobileMenu = useCallback(
    (e: SyntheticEvent) => {
      // Если e существует, вызываем preventDefault
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      if (showMobileMenu) {
        // Закрытие меню
        setIsClosing(true);
        setTimeout(() => {
          setMenuVisible(false);
          setShowMobileMenu(false);
          setIsClosing(false);
        }, 500); // Время анимации закрытия
      } else {
        // Открытие меню
        setShowMobileMenu(true);
        setMenuVisible(true);
      }
    },
    [setShowMobileMenu, setMenuVisible, setIsClosing, showMobileMenu],
  );

  const handleLogin = (e) => {
    // Проверяем, есть ли событие e, и только потом вызываем stopPropagation
    if (e && e.stopPropagation) {
      e.preventDefault();
      e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    }

    // Просто устанавливаем флаг открытия формы входа
    setIsSignInOpen(true);

    // Не закрываем мобильное меню при открытии формы,
    // иначе toggleMobileMenu закроет все, включая форму входа
    // if (onClose) {
    //   onClose();
    // }
  };

  const closeSignIn = () => {
    setIsSignInOpen(false);
  };

  const handleModesOpen = (e) => {
    if (e && e.stopPropagation) {
      e.preventDefault();
      e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    }

    onShow('PopularModal');
  };

  return (
    <>
      <nav className="menu_mob">
        <div className="menu_mob_div_1"></div>
        <div className="menu_mob_div_2"></div>
        <a href="/" className="menu_mob_item">
          <MenuMob1 />
          <span className="menu_mob_item_text">{t('Cases')}</span>
        </a>
        <a href="/" onClick={handleModesOpen} className="menu_mob_item">
          <MenuMob2 />
          <span className="menu_mob_item_text">{t('Modes')}</span>
        </a>
        {getProfile ? (
          <a href="/pay" className="menu_mob_item">
            <MenuMob3 color="#FFB800" />
            <span className="menu_mob_item_text">{t('Deposit')}</span>
          </a>
        ) : (
          <a href="/" onClick={handleLogin} className="menu_mob_item">
            <Login color="#FFB800" size={32} />
            <span className="menu_mob_item_text">{t('Login')}</span>
          </a>
        )}

        <a href="/wheel" className="menu_mob_item">
          <MenuWheel />
          <span className="menu_mob_item_text">{t('Bonus_Wheel')}</span>
        </a>
        <a href="/" onClick={toggleMobileMenu} className="menu_mob_item">
          <MenuMob5 isActive={showMobileMenu} />
          <span className="menu_mob_item_text">{t('Menu')}</span>
        </a>
      </nav>
      {menuVisible && (
        <MobileMenuModal
          onClose={toggleMobileMenu}
          isClosing={isClosing}
          balance={getProfile.money}
          coins={getProfile.battlePassCoins}
        />
      )}

      {isSignInOpen && <SignIn onClose={closeSignIn} />}
    </>
  );
};

export default memo(MenuMob);
