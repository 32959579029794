export default function Quantity(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm1-2.083a6.002 6.002 0 0 0 0-11.834V8h.793a.5.5 0 0 1 .353.146l1 1a.5.5 0 0 1-.353.854h-3.586a.5.5 0 0 0-.353.146l-.708.708a.5.5 0 0 0-.146.353v1.586a.5.5 0 0 0 .146.354l.708.707a.5.5 0 0 0 .353.146h3.586a.5.5 0 0 1 .353.854l-1 1a.5.5 0 0 1-.353.146H13v1.917Zm-2 0V16h-.793a.5.5 0 0 1-.353-.146l-1.708-1.707A.5.5 0 0 1 8 13.793v-3.586a.5.5 0 0 1 .146-.353l1.708-1.708A.5.5 0 0 1 10.207 8H11V6.083a6.002 6.002 0 0 0 0 11.834Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
