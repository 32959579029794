export default function DropVip(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={74}
      height={74}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.417 61.667V55.5h43.166v6.167H15.417Zm0-10.792-3.932-24.744c-.102 0-.218.013-.346.039a1.794 1.794 0 0 1-.347.038c-1.285 0-2.377-.45-3.276-1.349-.9-.899-1.35-1.991-1.35-3.276 0-1.284.45-2.376 1.35-3.276.899-.9 1.99-1.349 3.276-1.349 1.284 0 2.376.45 3.276 1.35.899.899 1.349 1.99 1.349 3.275 0 .36-.039.694-.116 1.002a6.59 6.59 0 0 1-.27.848l9.636 4.317 9.635-13.181a4.747 4.747 0 0 1-1.388-1.619 4.483 4.483 0 0 1-.539-2.158c0-1.285.45-2.377 1.349-3.276.9-.9 1.991-1.35 3.276-1.35 1.285 0 2.377.45 3.276 1.35.9.899 1.349 1.99 1.349 3.276 0 .77-.18 1.49-.54 2.158a4.745 4.745 0 0 1-1.387 1.619l9.635 13.181 9.636-4.317a6.59 6.59 0 0 1-.27-.848 4.124 4.124 0 0 1-.116-1.002c0-1.284.45-2.376 1.35-3.276.899-.9 1.99-1.349 3.275-1.349s2.377.45 3.276 1.35c.9.899 1.35 1.99 1.35 3.275s-.45 2.377-1.35 3.276c-.899.9-1.991 1.35-3.276 1.35-.102 0-.218-.014-.347-.04a1.787 1.787 0 0 0-.346-.038l-3.932 24.744H15.417Zm5.241-6.167h32.684l2.004-12.873-8.094 3.546L37 21.275 26.748 35.381l-8.094-3.546 2.004 12.873Z"
        opacity={0.24}
      />
    </svg>
  );
}
