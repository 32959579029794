import contracts from '../../../scss/images/ContractsModeNew.png';
import upgrades from '../../../scss/images/UpgradesModeNew.png';
import giveaways from '../../../scss/images/GiveawaysModesNew.png';
import bonusWheel from '../../../scss/images/BonusWheelModesNew.png';
import mode5 from '../../../scss/images/mode-5.png';

export const gameModeData = [
  {
    title: 'blog tag distributions',
    text: 'giveaways_desc',
    bg: 'bg-game-4',
    image: giveaways,
    count: '10 898',
  },
  {
    title: 'Free wheel',
    text: 'bonus_wheel_desc',
    bg: 'bg-game-1',
    image: bonusWheel,
    count: '155',
  },
  {
    title: 'Upgrades',
    text: 'coming_soon',
    bg: 'bg-game-2',
    image: upgrades,
    count: '0',
  },
  {
    title: 'Contracts',
    text: 'coming_soon',
    bg: 'bg-game-3',
    image: contracts,
    count: '0',
  },
  // {
  //   title: 'new_mode',
  //   text: 'coming_soon',
  //   bg: 'bg-game-5',
  //   image: mode5,
  //   count: '0',
  // },
];
