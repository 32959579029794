export default function Faq(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0156 20.0156C17.5312 20.0156 18 20.4375 18 21C18 21.5625 17.5312 21.9844 17.0156 21.9844H3.98438C2.90625 21.9844 2.01562 21.0938 2.01562 20.0156V6.98438C2.01562 6.46875 2.4375 6 3 6C3.5625 6 3.98438 6.46875 3.98438 6.98438V20.0156H17.0156ZM20.0156 2.01562C21.0938 2.01562 21.9844 2.90625 21.9844 3.98438V15.9844C21.9844 17.1094 21.0938 18 20.0156 18H8.01562C6.89062 18 6 17.1094 6 15.9844V3.98438C6 2.90625 6.89062 2.01562 8.01562 2.01562H20.0156ZM14.0156 15C14.5781 15 15.0469 14.5312 15.0469 13.9688C15.0469 13.3594 14.5781 12.8906 14.0156 12.8906C13.4531 12.8906 12.9375 13.3594 12.9375 13.9688C12.9375 14.5312 13.4062 15 14.0156 15ZM16.5 8.8125C17.0625 7.96875 16.875 6.89062 16.5 6.28125C16.0781 5.57812 15.2344 5.01562 13.9688 5.01562C12.8906 5.01562 12 5.53125 11.5312 6.28125C11.25 6.65625 11.4375 7.17188 11.8594 7.35938C12.1875 7.5 12.5625 7.40625 12.75 7.125C12.9844 6.75 13.4062 6.42188 14.0156 6.42188C15.3281 6.42188 15.6094 7.6875 15.1406 8.34375C14.6719 9 13.9219 9.42188 13.5 10.1719C13.3594 10.4062 13.3125 10.6875 13.2656 10.9688C13.2188 11.4375 13.5469 11.8125 14.0156 11.8125H14.0625C14.4375 11.8125 14.7188 11.5312 14.8125 11.1094C14.8125 10.9219 14.8594 10.7812 14.9531 10.6406C15.2812 10.0312 15.8906 9.75 16.5 8.8125Z"
        fill="#FFB800"
      />
    </svg>
  );
}
