export default function Sound(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5017_44026)">
        <path
          d="M18 16.7498C17.84 16.7498 17.69 16.6998 17.55 16.5998C17.22 16.3498 17.15 15.8798 17.4 15.5498C18.97 13.4598 18.97 10.5398 17.4 8.44978C17.15 8.11978 17.22 7.64978 17.55 7.39978C17.88 7.14978 18.35 7.21978 18.6 7.54978C20.56 10.1698 20.56 13.8298 18.6 16.4498C18.45 16.6498 18.23 16.7498 18 16.7498Z"
          fill="white"
        />
        <path
          d="M19.83 19.2498C19.67 19.2498 19.52 19.1998 19.38 19.0998C19.05 18.8498 18.98 18.3798 19.23 18.0498C21.9 14.4898 21.9 9.50978 19.23 5.94978C18.98 5.61978 19.05 5.14978 19.38 4.89978C19.71 4.64978 20.18 4.71978 20.43 5.04978C23.5 9.13978 23.5 14.8598 20.43 18.9498C20.29 19.1498 20.06 19.2498 19.83 19.2498Z"
          fill="white"
        />
        <path
          d="M14.02 3.78021C12.9 3.16021 11.47 3.32021 10.01 4.23021L7.09 6.06021C6.89 6.18021 6.66 6.25021 6.43 6.25021H5.5H5C2.58 6.25021 1.25 7.58021 1.25 10.0002V14.0002C1.25 16.4202 2.58 17.7502 5 17.7502H5.5H6.43C6.66 17.7502 6.89 17.8202 7.09 17.9402L10.01 19.7702C10.89 20.3202 11.75 20.5902 12.55 20.5902C13.07 20.5902 13.57 20.4702 14.02 20.2202C15.13 19.6002 15.75 18.3102 15.75 16.5902V7.41021C15.75 5.69021 15.13 4.40021 14.02 3.78021Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5017_44026">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
