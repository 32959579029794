export default function MenuMob5({ isActive, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <g className={`burger-icon ${isActive ? 'active' : ''}`}>
        <path
          className="line top"
          fill="currentColor"
          d="M21.8 7.75h-18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75Z"
        />
        <path
          className="line middle"
          fill="currentColor"
          d="M21.8 12.75h-18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75Z"
        />
        <path
          className="line bottom"
          fill="currentColor"
          d="M21.8 17.75h-18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75Z"
        />
      </g>
      <style>{`
        .burger-icon .line {
          transition: transform 0.3s ease, opacity 0.3s ease;
          transform-origin: center;
        }

        .burger-icon.active .top {
          transform: translateY(3.95px) translateX(-3.2px) rotate(45deg);
        }

        .burger-icon.active .middle {
          opacity: 0;
        }

        .burger-icon.active .bottom {
          transform: translateY(-3.05px) translateX(-3px) rotate(-45deg);
        }
      `}</style>
    </svg>
  );
}
