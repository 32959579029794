import { FunctionComponent, useState } from 'react';
import './mobileMenuModal.scss';
import logo_mob from '../../../../scss/images/logo-mob.svg';
import { Link, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from '~shared/frontend/ui/button/button';
import Sound from './sound';
import Faq from './faq';
import Login from '../../header-bottom/login';
import ArrowRight from './arrow-right';
import Support from './support';
import Telegram from '~frontend/pages/layouts/icons/soc-tg';
import Discord from '~frontend/pages/layouts/icons/soc-discord';
import Twitch from '~frontend/pages/layouts/icons/soc-twitch';
import X from '~frontend/pages/layouts/icons/soc-x';
import TikTok from '~frontend/pages/layouts/icons/soc-tiktok';
import Youtube from '~frontend/pages/layouts/icons/soc-youtube';
import LanguageModal from '~frontend/components/modals/language-modal/languageModal';
import SignIn from '~frontend/components/modals/sign-in/signIn';
import { useTranslation } from 'react-i18next';
import './balance-section.scss';
import { useProfile } from '~frontend/components/hooks';
import Copy from './copy';
import BalanceSection from '~frontend/components/profile/profile-stats/BalanceSection';
import './balance-section.scss';
import Logout from './logout';
import Partner from '../../header-bottom/partner';

const MobileMenuModal: FunctionComponent<any> = ({ onClose, isClosing }) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const { i18n } = useTranslation();
  const getProfile = useProfile();

  const langImage = {
    ru: require(`../../../../scss/images/ru.svg`),
    en: require(`../../../../scss/images/en.svg`),
    de: require(`../../../../scss/images/de.svg`),
    tr: require(`../../../../scss/images/tr.svg`),
    pl: require(`../../../../scss/images/pl.svg`),
    pt: require(`../../../../scss/images/pt.svg`),
    th: require(`../../../../scss/images/th.svg`),
    hi: require(`../../../../scss/images/hi.svg`),
  };

  const openLanguageModal = (e) => {
    e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    setIsLanguageModalOpen(true);
  };

  const closeLanguageModal = () => {
    setIsLanguageModalOpen(false);
  };

  const handleLogin = (e) => {
    // Проверяем, есть ли событие e, и только потом вызываем stopPropagation
    if (e && e.stopPropagation) {
      e.stopPropagation(); // Предотвращаем закрытие мобильного меню
    }

    // Просто устанавливаем флаг открытия формы входа
    setIsSignInOpen(true);

    // Не закрываем мобильное меню при открытии формы,
    // иначе toggleMobileMenu закроет все, включая форму входа
    // if (onClose) {
    //   onClose();
    // }
  };
  const navigate = useNavigate();

  const handleSupportClick = () => {
    if (window.jivo_api) {
      document.querySelector('jdiv').className = 'active';
      window.jivo_api.open();
    }
  };

  const handleToProfileClick = () => {
    navigate('/profile');
    onClose();
  };

  const closeSignIn = () => {
    setIsSignInOpen(false);
  };

  const handleCopyClick = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(getProfile?.id + '' || '');
      } catch (err) {
        console.error('Ошибка при копировании ID:', err);
      }
    } else {
      console.error(
        'API navigator.clipboard не поддерживается в этом браузере.',
      );
    }
  };

  return (
    <>
      <div className={`mobile_menu ${isClosing ? 'closing' : ''}`}>
        <div className="mobile_menu_header" onClick={onClose}>
          <Link to="/" className="header_logo_mob">
            <LazyLoadImage src={logo_mob} alt="logo" width={44} height={32} />
          </Link>
          <Button is_icon={true} icon={<Sound />} className="sound_btn" />
          {getProfile && (
            <Button
              className="btn1_blue"
              text="Partner"
              href="/referral"
              icon={<Partner />}
            />
          )}
          {!getProfile && (
            <Button
              className="btn1_yellow"
              text="Войти"
              icon={<Login />}
              onClick={handleLogin}
            />
          )}
        </div>
        <div className="mobile_menu_content">
          {getProfile && (
            <>
              {' '}
              <div
                className="mobile_menu_profile"
                onClick={handleToProfileClick}
              >
                <LazyLoadImage
                  src={getProfile?.avatar}
                  alt="Аватар профиля"
                  width={44}
                  height={44}
                  className="avatar_image"
                />
                <div className="profile_info_mobile_descr">
                  <span>{getProfile?.userName}</span>
                  <span
                    className="profile_info_mobile_id"
                    onClick={handleCopyClick}
                  >
                    ID {getProfile?.id} <Copy size={16} color="#47485D" />
                  </span>
                </div>
                <ArrowRight className="arrow_right" />
              </div>
              <BalanceSection
                balance={getProfile?.money / 100}
                coins={getProfile?.coins}
                className={`mobile_balance_section`}
              />
            </>
          )}
          {!getProfile && (
            <Link to="/" className="mobile_menu_login" onClick={handleLogin}>
              <Login />
              <span>Войти</span>
            </Link>
          )}
          <div className="mobile_menu_language" onClick={openLanguageModal}>
            <img
              src={langImage[i18n.language]}
              alt="lang"
              width={24}
              height={24}
            />
            <span>Language</span>
            <ArrowRight className="arrow_right" />
          </div>
          <div className="mobile_menu_links">
            <a href="/faq">
              <Faq />
              <span>FAQ</span>
              <ArrowRight className="arrow_right" />
            </a>
            <div className="mobile_menu_links_divider"></div>
            <Link to="/" onClick={handleSupportClick}>
              <Support />
              <span>Support</span>
              <ArrowRight className="arrow_right" />
            </Link>
          </div>
          <div className="mobile_menu_socials">
            <a href="#" className="mobile_menu_social_item">
              <Telegram />
            </a>
            <a href="#" className="mobile_menu_social_item">
              <Discord />
            </a>
            <a href="#" className="mobile_menu_social_item">
              <Twitch />
            </a>
            <a href="#" className="mobile_menu_social_item">
              <X />
            </a>
            <a href="#" className="mobile_menu_social_item">
              <TikTok />
            </a>
            <a href="#" className="mobile_menu_social_item">
              <Youtube />
            </a>
          </div>
          {getProfile && (
            <a href="/auth/logout" className="mobile_menu_logout">
              <Logout />
              <span>Log out</span>
            </a>
          )}
        </div>
      </div>

      {isLanguageModalOpen && <LanguageModal onClose={closeLanguageModal} />}

      {isSignInOpen && <SignIn onClose={closeSignIn} />}
    </>
  );
};

export default MobileMenuModal;
