export default function SupportWhite(props) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.23438C19.6094 9.51562 19.9844 10.125 19.9844 10.8281V13.125C19.9844 13.875 19.6094 14.4844 19 14.7656V15.9844C19 17.1094 18.1094 18 16.9844 18H10C9.4375 18 9.01562 17.5312 9.01562 17.0156C9.01562 16.4531 9.4375 15.9844 10 15.9844H16.9844V8.90625C16.9844 4.96875 13.75 1.78125 9.76562 1.92188C5.96875 2.01562 3.01562 5.34375 3.01562 9.1875V14.0156C3.01562 14.5312 2.54688 15 1.98438 15C0.90625 15 0.015625 14.1094 0.015625 12.9844V11.0156C0.015625 10.2656 0.390625 9.60938 1 9.28125C1 3.65625 5.3125 0 10 0C14.7344 0 19 3.75 19 9.23438ZM6.01562 9.98438C6.01562 10.5469 6.4375 11.0156 7 11.0156C7.5625 11.0156 7.98438 10.5469 7.98438 9.98438C7.98438 9.46875 7.5625 9 7 9C6.4375 9 6.01562 9.46875 6.01562 9.98438ZM12.0156 9.98438C12.0156 10.5469 12.4375 11.0156 13 11.0156C13.5625 11.0156 13.9844 10.5469 13.9844 9.98438C13.9844 9.46875 13.5625 9 13 9C12.4375 9 12.0156 9.46875 12.0156 9.98438ZM16 8.01562C12.8594 8.01562 10.1875 6.1875 8.875 3.5625C8.35938 6.23438 6.48438 8.4375 4 9.46875C3.76562 5.53125 7 3 10.0469 3C13.0469 3 15.5312 5.20312 16 8.01562Z"
        fill="white"
      />
    </svg>
  );
}
