export default function Logout(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84143 8.01238C10.1514 4.41238 12.0014 2.94238 16.0514 2.94238H16.1814C20.6514 2.94238 22.4414 4.73238 22.4414 9.20238V15.7224C22.4414 20.1924 20.6514 21.9824 16.1814 21.9824H16.0514C12.0314 21.9824 10.1814 20.5324 9.85143 16.9924"
        stroke="#B3B5C6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9414 12.4521H4.5614"
        stroke="#B3B5C6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.79141 9.10254L3.44141 12.4525L6.79141 15.8025"
        stroke="#B3B5C6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
