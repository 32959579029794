import { memo, FunctionComponent, ImgHTMLAttributes } from 'react';

export const preloadImage = (src: string) =>
  new Promise((r) => {
    const image = new Image();
    image.onload = r;
    image.onerror = r;
    image.src = src;
  });

interface SteamItemImage extends ImgHTMLAttributes<HTMLImageElement> {
  size?: string;
  url?: string;
  src?: string;
  alt?: string;
}

const SteamItemImage: FunctionComponent<SteamItemImage> = ({
  size = '',
  url,
  src,
  ...props
}) => {
  return (
    <img
      className="top_drop_item_view_image"
      src={url ? `${url}/${size}` : src}
      {...props}
    />
  );
};

export default memo(SteamItemImage);
