export default function Clock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8 1.333C4.327 1.333 1.333 4.327 1.333 8c0 3.673 2.994 6.667 6.667 6.667 3.673 0 6.667-2.994 6.667-6.667 0-3.673-2.994-6.667-6.667-6.667Zm2.9 9.047a.497.497 0 0 1-.687.173L8.147 9.32c-.514-.307-.894-.98-.894-1.573V5.013c0-.273.227-.5.5-.5.274 0 .5.227.5.5v2.734c0 .24.2.593.407.713l2.067 1.233c.24.14.32.447.173.687Z"
      />
    </svg>
  );
}
